<template src="./NewRecommededCourse.html">
</template>

<script>

import coursesService from "@/services/Courses";
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";

export default {
  name: "NewRecommendedCourse",
  components:{
    UploadImageModal
  },
  data() {
    return {
      file: null,
      courseName: '',
      universityName: '',
      courseLink: '',
      level: '',
      imageDescription: 'fotoDescripción',
      type: '',
      course: "type",
      AllCoursesByInstitution:[],
      selectedCourse: null,
      isActive: false,
      infoFile: {}
    }
  },
  methods:{
    async getAllCoursesByInstitution(){
      try {
        this.AllCoursesByInstitution = (await coursesService.getAllCoursesByInstitution())[0]
        console.log('getAllCoursesByInstitution', this.AllCoursesByInstitution);
      }catch (e) {
        console.log('e',e);
      }
    },
    async createRecommendedCourse(){
      try {
        const imageLink = await coursesService.uploadFile('imagesRecommendedCourse', 'imagesRecommendedCourse', this.infoFile.name, this.infoFile.file);
        console.log('url', imageLink);
        const info = {
          name: this.courseName,
          level: this.level,
          url: this.courseLink,
          image: imageLink,
          imageDescription: this.infoFile.description,
          type: this.type,
          institution: this.universityName,
          course: this.course
        }
        await coursesService.createRecommendedCourse(info);
        this.$router.push({ name: "RecommendedCourses"});
        this.$buefy.toast.open({
          duration: 5000,
          message: "Curso recomendado creado correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      }catch (e) {
        console.log('e',e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Error creando curso!",
          type: "is-error",
        });
      }
    },
  },
  mounted() {
    this.getAllCoursesByInstitution();
  }
}
</script>

<style scoped lang="scss" src="./NewRecommendedCourses.scss">
</style>
